<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="预警名称：">
					<a-input
						class="search-input"
						placeholder="请输入"
						v-model="formSearch.alarmName"
						allowClear
					/>
				</a-form-item>
				<a-form-item label="预警类型：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.alarmType"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.typeName"
						>
							{{ item.typeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="预警等级：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.alarmGrade"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option
							v-for="item in gradeList"
							:key="item.id"
							:value="item.gradeValue"
						>
							{{ item.gradeValue }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="预警时间：">
					<a-range-picker v-model="formSearch.alarmTime"></a-range-picker>
				</a-form-item>
				<a-form-item label="预警状态：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.dealStatus"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option value="0">未处理</a-select-option>
						<a-select-option value="1">已处理</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{
					total,
					hideOnSinglePage: true,
					current: searchData.current
				}"
				@change="pageNumChange"
			>
				<span slot="unProcessedDayNum" slot-scope="text">
					{{ text ? text + '天' : '-' }}
				</span>
				<span slot="dealStatus" slot-scope="text">
					{{ text ? '已处理' : '未处理' }}
				</span>
				<a slot="action" slot-scope="text, record">
					<a href="javascript: void(0)" @click="editAlarmEvent(record)">详情</a>
				</a>
			</a-table>
		</section>
		<a-drawer
			class="drawer_module"
			title="预警详情"
			placement="right"
			:width="400"
			:maskClosable="false"
			:visible="editVisible"
			@close="btnCancel"
		>
			<a-form
				:form="form"
				:colon="false"
				:labelCol="{ span: 6 }"
				:wrapperCol="{ span: 18 }"
			>
				<template v-if="dataSelected">
					<a-form-item label="预警名称">
						{{ dataSelected.alarmName }}
					</a-form-item>
					<a-form-item label="事件描述">
						{{ dataSelected.alarmMessage }}
					</a-form-item>
					<a-form-item label="预警类型">
						{{ dataSelected.alarmType }}
					</a-form-item>
					<a-form-item label="预警等级">
						{{ dataSelected.alarmGrade }}
					</a-form-item>
					<a-form-item label="所属区域">
						{{ dataSelected.alarmArea }}
					</a-form-item>
					<a-form-item label="预警时间">
						{{ dataSelected.createTime }}
					</a-form-item>
					<a-form-item label="状态">
						{{ dataSelected.dealStatus ? '已处理' : '未处理' }}
					</a-form-item>
					<a-form-item label="未处理时间" v-if="dataSelected.dealStatus === 0">
						{{
							dataSelected.unProcessedDayNum
								? dataSelected.unProcessedDayNum + '天'
								: '-'
						}}
					</a-form-item>
					<a-form-item
						:wrapper-col="{ span: 18, offset: 6 }"
						v-if="dataSelected.dealStatus === 0"
					>
						<a-button type="primary" @click="btnConfirm">确认处理</a-button>
						<a-button style="margin-left: 10px" @click="btnCancel"
							>关闭</a-button
						>
					</a-form-item>
				</template>
			</a-form>
		</a-drawer>
	</section>
</template>

<script>
	import moment from 'moment'
	export default {
		name: 'AlarmEvent',
		data() {
			return {
				form: this.$form.createForm(this),
				typeList: [],
				gradeList: [],
				formSearch: {
					alarmName: '',
					alarmType: '',
					alarmGrade: '',
					alarmTime: ['', ''],
					dealStatus: ''
				},
				searchData: {
					alarmName: '',
					alarmType: '',
					alarmGrade: '',
					startDate: '',
					endDate: '',
					dealStatus: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '预警名称',
						dataIndex: 'alarmName',
						align: 'center'
					},
					{
						title: '事件描述',
						dataIndex: 'alarmMessage',
						align: 'center'
					},
					{
						title: '预警类型',
						dataIndex: 'alarmType',
						align: 'center'
					},
					{
						title: '预警等级',
						dataIndex: 'alarmGrade',
						align: 'center'
					},
					{
						title: '所属区域',
						dataIndex: 'alarmArea',
						align: 'center'
					},
					{
						title: '预警时间',
						dataIndex: 'createTime',
						align: 'center'
					},
					{
						title: '未处理时间',
						dataIndex: 'unProcessedDayNum',
						align: 'center',
						scopedSlots: { customRender: 'unProcessedDayNum' }
					},
					{
						title: '状态',
						dataIndex: 'dealStatus',
						align: 'center',
						scopedSlots: { customRender: 'dealStatus' }
					},
					{
						title: '操作',
						dataIndex: 'action',
						align: 'center',
						scopedSlots: { customRender: 'action' }
					}
				],
				tableData: [],
				total: 0,
				editVisible: false,
				dataSelected: null
			}
		},
		mounted() {
			this.getAlarmTypeAll()
			this.getAlarmGradeAll()
			this.queryAlarmEvent()
		},
		methods: {
			getAlarmTypeAll() {
				this.$api.getAlarmTypeAll().then((res) => {
					if (res.code === 200) {
						this.typeList = res.data
					}
				})
			},
			getAlarmGradeAll() {
				this.$api.getAlarmGradeAll().then((res) => {
					if (res.code === 200) {
						this.gradeList = res.data
					}
				})
			},
			queryAlarmEvent() {
				this.$api.queryAlarmEvent(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = res.data.records
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									return item
							  })
							: []
						this.total = res.data.total
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmEvent()
			},
			btnQuery() {
				this.searchData.alarmName = this.formSearch.alarmName
				this.searchData.alarmType = this.formSearch.alarmType
				this.searchData.alarmGrade = this.formSearch.alarmGrade
				this.searchData.startDate = this.formSearch.alarmTime[0]
					? moment(this.formSearch.alarmTime[0]).format('YYYY-MM-DD') +
					  ' 00:00:00'
					: ''
				this.searchData.endDate = this.formSearch.alarmTime[1]
					? moment(this.formSearch.alarmTime[1]).format('YYYY-MM-DD') +
					  ' 23:59:59'
					: ''
				this.searchData.dealStatus = this.formSearch.dealStatus
				this.searchData.current = 1
				this.queryAlarmEvent()
			},
			btnReset() {
				this.searchData.alarmName = this.formSearch.alarmName = ''
				this.searchData.alarmType = this.formSearch.alarmType = ''
				this.searchData.alarmGrade = this.formSearch.alarmGrade = ''
				this.searchData.startDate = this.searchData.endDate = ''
				this.formSearch.alarmTime = ['', '']
				this.searchData.dealStatus = this.formSearch.dealStatus = ''
				this.searchData.current = 1
				this.queryAlarmEvent()
			},
			editAlarmEvent(record) {
				this.dataSelected = record
				this.editVisible = true
			},
			btnConfirm() {
				this.$api
					.handleAlarmEvent({
						id: this.dataSelected.id,
						dealStatus: 1
					})
					.then((res) => {
						if (res.code === 200) {
							this.editVisible = false
							this.queryAlarmEvent()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			},
			btnCancel() {
				this.editVisible = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.search-input {
		width: 120px;
	}
</style>
